import type { PartnerService, Service } from '@/features/services';
import type { Specialization } from '@/features/specializations';
import { type Coords } from '@glow/shared/lib/coords';
import { type Photo } from '@glow/shared/utils/types';
import { type City } from '@/api/cities';
import { type PaginationMeta } from '@glow/shared/lib/http';
import { type RouteLocationRaw } from 'vue-router';
import { type ClosestMetroStation } from './components/ClosestStations.vue';

export type PartnerType = 'master' | 'company';
export type PluralPartnerType = 'masters' | 'companies';

export const SORT_OPTIONS = [
  'rating',
  'reviews',
  'popularity',
  'experience',
  'price',
] as const;
export type PartnerSort = (typeof SORT_OPTIONS)[number];
export const PARTNER_DEFAULT_SORT: PartnerSort = 'popularity';

export type PartnerLocation = {
  name: string;
  location: Coords;
  workPlaceId?: number;
  company?: {
    id: number;
    slug: string;
  };
  photos: Photo[];
  closestMetroStations?: ClosestMetroStation[];
};

export type Partner = {
  name: string;
  reviewCount: number;
  link: RouteLocationRaw;
  rating: number;
  isLiked: boolean;
  about: string | null;
  avatarUrl: string | null;
  yearsOfExperience: number | null;
  service?: PartnerService;
  services?: Service[];
  specialization?: Specialization;
  locations: PartnerLocation[];

  instagramProfile?: string;
  telegramProfile?: string;
  whatsappProfile?: string;
  youtubeProfile?: string;
  vkProfile?: string;
  odnoklassnikiProfile?: string;
  personalSite?: string;
  phone?: string;
  email?: string;
};

export type PartnerFilters = {
  city?: string;
  address?: string;
  metroStation?: number;
  radius?: number;

  specialization?: string;
  service?: string;

  isHomeVisitAvailable?: boolean;
  priceRange?: [number, number];
  appointableAt?: Date;
  sort: PartnerSort;
  bounds?: number[];
};

export type PartnerExperienceRecord = {
  name: string;
  startYear: number;
  endYear: number | null;
};

export type PartnerExperienceRecordGroup = {
  name: string;
  records: PartnerExperienceRecord[];
};

export type PartnerCatalogMeta = PaginationMeta & {
  city: City;
  address: string;
  center: Coords;
  title: string;
  headTitle: string;
  shortTitle: string;
  description: string;
  isIndexable: boolean;
};

export type PartnerDetailsMeta = {
  description: string;
};

export type PartnerMapObject = {
  id: number;
  slug: string;
  partnerType: PartnerType;
  location: Coords;
  citySlug: string;
  name: string;
  avatarUrl: string | null;
};
